// szczegóły produktu

import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Tag } from 'react-bootstrap-icons';
import { Trans } from 'react-i18next';

import { useGetProductExportImages } from 'api';
import { IProduct, IProductLabel } from 'api/types';
import { useSelector } from 'store';
import { useRWD, useNotifications } from 'hooks';
import { Label, Link, Modal, Collapse, Loader } from 'components/controls';

import styles from 'theme/pages/Product/components/Details/Details.module.scss';
import { SizingTable, Download, TechnicalIcon } from 'assets/icons';

// typ danych wejściowych
interface IProps {
  product: IProduct;
  labels: IProductLabel[];
}

const Details: FC<IProps> = ({ product, labels }) => {
  const { profile } = useSelector((state) => state.auth);
  const { isMobile } = useRWD();
  const { t } = useTranslation();
  const { showErrorMessage } = useNotifications();

  // loader
  const [isLoading, setIsLoading] = useState(false);

  const { refetch: downloadImages } = useGetProductExportImages(product.id, {
    enabled: false,
    onSuccess: (data) => {
      fetch(data.url)
        .then((resp) => resp.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;

          a.download = data.file_name;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);

          setIsLoading(false);
        })
        .catch(() => showErrorMessage(t('Błąd pobierania pliku')));
    }
  });

  const handleClick = () => {
    setIsLoading(true);
    downloadImages();
  };

  // pokazywanie popupa z tabelą rozmiarów
  const [isSizingTableVisible, setSizingTableVisible] = useState(false);

  // pokazywanie popupa z GPSR
  const [technicalInformationVisible, setTechnicalInformationVisible] = useState(false);

  // aktualna jednostka
  const unit = useMemo(
    () => product.units.find((unit) => unit.unit_id === product.units[0]?.unit_id),
    [product]
  );

  const sizingTableContent = () => ({ __html: product.sizing_table });

  const technicalInformationContent = () => ({
    __html: product.gpsr_attributes_dto?.[0].description
  });

  const renderDescription = () =>
    isMobile ? (
      <Collapse
        collapsed={false}
        title={
          <div className={styles.collapseTitle}>
            <Trans>Opis</Trans>
          </div>
        }>
        <div className={styles.description}>
          <div
            itemProp="description"
            className={styles.text}
            dangerouslySetInnerHTML={{ __html: product.description_full }}
          />
        </div>
      </Collapse>
    ) : (
      <div className={styles.description}>
        <span className={styles.label}>
          <Trans>Opis</Trans>
        </span>
        <div
          itemProp="description"
          className={styles.text}
          dangerouslySetInnerHTML={{ __html: product.description_full }}
        />
      </div>
    );

  return (
    <div
      className={classnames(styles.productDetails, 'StylePath-Pages-Product-components-Details')}>
      <div className={styles.mainContent}>
        {!isMobile && (
          <>
            <span className={styles.producer}>
              <Link
                to={`/products?filter_attributes=PRODUCER=${product.producer_id}`}
                itemScope
                itemProp="brand"
                itemType="http://schema.org/Brand">
                {product.producer_name}
                <meta itemProp="name" content={product?.producer_name} />
              </Link>
            </span>
            <h1 itemProp="name" className={styles.title}>
              {product.title}
            </h1>
          </>
        )}
        {profile?.role !== 'ROLE_OPEN_PROFILE' && unit && (
          <div className={styles.priceWrapper}>
            <span className={classnames(styles.nettoPrice, styles.oldPrice)}>
              {product.old_price_formatted_to_show && (
                <>
                  {product.old_price_formatted_to_show?.replaceAll('.', ',')} {product.currency}{' '}
                  <Trans>netto</Trans>
                </>
              )}
            </span>
            <span
              className={classnames(styles.nettoPrice, {
                [styles.discountPrice]: !!product.old_price_formatted_to_show
              })}>
              {product.price_formatted_to_show?.replaceAll('.', ',')} {product.currency}{' '}
              <Trans>netto</Trans>
            </span>
            <span className={styles.eurPrice}>
              <Trans>ok.</Trans> {product.eur_price_formatted_to_show.replace('.', ',')} EUR
            </span>
            <span className={styles.retailPrice}>
              <Trans>sugerowana cena detaliczna:</Trans>{' '}
              {product.price_suggested_old_formatted_to_show && (
                <span className={styles.retailOldPrice}>
                  {product.price_suggested_old_formatted_to_show.replace('.', ',')}{' '}
                </span>
              )}
              <span
                className={classnames({
                  [styles.retailPromoPrice]: !!product.price_suggested_old_formatted_to_show
                })}>
                {product.price_suggested_formatted_to_show.replace('.', ',')} {product.currency}{' '}
              </span>
              <Trans>brutto</Trans>
            </span>
          </div>
        )}

        {labels.length > 0 && !isMobile && (
          <div className={styles.labels}>
            {labels.map((label) => (
              <Label key={label.type} label={label} />
            ))}
          </div>
        )}

        {product.description_full && renderDescription()}

        {profile?.role === 'ROLE_OPEN_PROFILE' && (
          <Link
            to={`/login?return_url=${encodeURIComponent(location.pathname + location.search)}`}
            className={styles.loginButton}>
            <Trans>Zaloguj się</Trans>{' '}
            <small>
              <Trans>aby sprawdzić cenę</Trans>
            </small>
          </Link>
        )}
      </div>

      {!!product.promotion && (
        <div className={styles.dashedBlock}>
          <div className={styles.promotion} style={{ color: product.promotion.color }}>
            <Tag />
            {product.promotion.name}
            <Link to={`/products${product.promotion.filters}`}>
              <Trans>Zobacz więcej</Trans>
            </Link>
          </div>
        </div>
      )}

      {product.sizing_table && (
        <div className={styles.sizingTable} onClick={() => setSizingTableVisible(true)}>
          <SizingTable />
          <span>
            <Trans>Tabela rozmiarów</Trans>
          </span>
        </div>
      )}

      {!!product.gpsr_attributes_dto.length && (
        <div className={styles.sizingTable} onClick={() => setTechnicalInformationVisible(true)}>
          <TechnicalIcon />
          <span>
            <Trans>Informacje techniczne</Trans>
          </span>
        </div>
      )}

      {profile?.role !== 'ROLE_OPEN_PROFILE' && !isMobile && (
        <button disabled={isLoading} className={styles.download} onClick={() => handleClick()}>
          <Download />
          <span>
            <Trans>Pobierz zdjęcia</Trans>
          </span>
          {isLoading && <Loader />}
        </button>
      )}
      {isSizingTableVisible && (
        <Modal
          onClose={() => {
            setSizingTableVisible(false);
          }}>
          <h3 className={styles.sizingTableTitle}>
            <Trans>Tabela rozmiarów</Trans>
          </h3>
          <div dangerouslySetInnerHTML={sizingTableContent()} />
        </Modal>
      )}

      {technicalInformationVisible && (
        <Modal
          onClose={() => {
            setTechnicalInformationVisible(false);
          }}>
          <h3 className={styles.sizingTableTitle}>
            <Trans>Informacje techniczne</Trans>
          </h3>
          <div dangerouslySetInnerHTML={technicalInformationContent()} />
        </Modal>
      )}
    </div>
  );
};

export default Details;
